import { Link } from 'react-router-dom'
import myPicture from '../../assets/images/profilePicture.png'
import './myself.scss'

const MySelf = () => {
    return (
        <>
            <div className="myself">
                <div className="myself__content">
                    <h2>Qui suis-je ?</h2>
                    <div>
                        <p>
                            Directrice artistique depuis 5 ans, j’ai aussi été
                            amenée à travailler en web design et graphisme
                            print, en 360, des intentions jusqu’à la réalisation
                            finale.
                        </p>
                        <p>
                            Vous constaterez que mes compétences sont assez
                            versatiles, car j’éprouve ce besoin d’enrichir
                            continuellement mes connaissances.
                        </p>
                        <p>J’ai hâte de faire votre connaissance !</p>
                    </div>

                    <Link className="linkBtn link-mbottom" to="/curriculum">
                        Voir le CV
                    </Link>
                    <a
                        className="linkBtn link-mbottom"
                        href="https://www.linkedin.com/in/melanie-chabrol/details/recommendations/?detailScreenTabIndex=0"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Voir mes recommandations
                    </a>
                </div>
                <img
                    className="myself__picture"
                    src={myPicture}
                    alt="Mélanie Chabrol"
                />
            </div>
        </>
    )
}

export default MySelf
