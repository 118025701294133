import { Link } from 'react-router-dom'

const ShowMore = () => {
    return (
        <div className="show-more">
            <h2>Envie d'en voir plus ?</h2>
            <Link className="linkBtn link-mbottom" to="/portfolio">
                Voir le portfolio
            </Link>
            <a
                className="linkBtn link-mbottom"
                href="https://www.linkedin.com/in/melanie-chabrol/details/recommendations/?detailScreenTabIndex=0"
                target="_blank"
                rel="noopener noreferrer"
            >
                Voir mes recommandations
            </a>
        </div>
    )
}

export default ShowMore
