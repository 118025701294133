import { NavLink } from 'react-router-dom'
import './navbarDesktop.scss'

const NavbarDesktop = () => {
    return (
        <nav className="navbar__desktop">
            <div className="link__item">
                <NavLink
                    className={({ isActive }) =>
                        isActive ? 'link link--active' : 'link'
                    }
                    to="/"
                >
                    HOME
                </NavLink>
                <svg
                    className="link__dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <circle cx="7" cy="7" r="7" fill="black" />
                </svg>
            </div>

            <div className="link__item">
                <NavLink
                    className={({ isActive }) =>
                        isActive ? 'link link--active' : 'link'
                    }
                    to="/curriculum"
                >
                    CV
                </NavLink>
                <svg
                    className="link__dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <circle cx="7" cy="7" r="7" fill="black" />
                </svg>
            </div>

            <div className="link__item">
                <NavLink
                    className={({ isActive }) =>
                        isActive ? 'link link--active' : 'link'
                    }
                    to="/portfolio"
                >
                    PORTFOLIO
                </NavLink>
                <svg
                    className="link__dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <circle cx="7" cy="7" r="7" fill="black" />
                </svg>
            </div>
            <div className="link__item">
                <NavLink
                    className={({ isActive }) =>
                        isActive ? 'link link--active' : 'link'
                    }
                    to="/veille"
                >
                    MA VEILLE
                </NavLink>
                <svg
                    className="link__dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <circle cx="7" cy="7" r="7" fill="black" />
                </svg>
            </div>
            <div className="link__item">
                <NavLink
                    className={({ isActive }) =>
                        isActive ? 'link link--active' : 'link'
                    }
                    to="/timeline"
                >
                    MA TIMELINE
                </NavLink>
                <svg
                    className="link__dot"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <circle cx="7" cy="7" r="7" fill="black" />
                </svg>
            </div>
        </nav>
    )
}

export default NavbarDesktop
