import './veille.scss'
import PageTitle from '../components/molecules/pageTitle'

const VeillePage = () => {
    return (
        <main className="page-anim">
            <PageTitle title="Ma Veille" subtitle="Actualités et outils UX" />
            <div className="veille-iframe">
                <iframe
                    title="Veille UX"
                    src="https://www.pearltrees.com/melanie_chabrol?embed=2&d=202501261359"
                    width="100%"
                    height="783"
                    style={{ border: '0px' }}
                    allowtransparency="true"
                ></iframe>
            </div>
        </main>
    )
}

export default VeillePage
